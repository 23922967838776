<template>
    <div class="series-container">
        <div class="series-banner">
            <img v-if="getseries.serieslogo" :src="`https://ipfs.io/ipfs/${getseries.serieslogo}`" class="series-banner-img" />
            <img v-if="getseries.seriesbanner" :src="`https://ipfs.io/ipfs/${getseries.seriesbanner}`" class="series-logo" />
        </div>
        <div class="series-url-content">
            <div class="deit-box" @click="switchShow" v-if="!isShow">
                <img src="../../assets/images/edit-img.png" class="edit-img" />
                <div>EDIT</div>
            </div>
            <div class="preview-btn" @click="switchShow" v-if="isShow&&edit == 'true'">
                <img src="../../assets/images/previewIcon.png" class="previewIcon" />
                <div class="preview-btn-name">PREVIEW</div>
            </div>
            <div class="preview-btn" @click="switchShow" v-if="isShow && getseries.edit == true">
                <img src="../../assets/images/previewIcon.png" class="previewIcon" />
                <div class="preview-btn-name">PREVIEW</div>
            </div>
            <div class="series-url-list" v-if="getseries.twitter || getseries.discord || getseries.medium || getseries.reddit">
                <div class="series-url-box" v-if="!getseries.twitter">
                    <img src="../../assets/images/seriesUrlImg1.png" class="seriesUrl1" />
                </div>
                <div class="series-url-box" v-if="getseries.twitter">
                    <a target="_blank" :href="getseries.twitter">
                        <img src="../../assets/images/seriesUrl1.png" class="seriesUrl1" />
                    </a>
                </div>
                <div class="series-url-box" v-if="!getseries.discord">
                    <img src="../../assets/images/seriesUrlImg2.png" class="seriesUrl1" />
                </div>
                <div class="series-url-box" v-if="getseries.discord">
                    <a target="_blank" :href="getseries.discord">
                        <img src="../../assets/images/seriesUrl2.png" class="seriesUrl1" />
                    </a>
                </div>
                <div class="series-url-box" v-if="!getseries.medium">
                    <img src="../../assets/images/seriesUrlImg3.png" class="seriesUrl1" />
                </div>
                <div class="series-url-box" v-if="getseries.medium">
                    <a target="_blank" :href="getseries.medium">
                        <img src="../../assets/images/seriesUrl3.png" class="seriesUrl1" />
                    </a>
                </div>
                <div class="series-url-box" v-if="!getseries.reddit">
                    <img src="../../assets/images/seriesUrlImg4.png" class="seriesUrl1" />
                </div>
                <div class="series-url-box" v-if="getseries.reddit">
                    <a target="_blank" :href="getseries.reddit">
                        <img src="../../assets/images/seriesUrl4.png" class="seriesUrl1" />
                    </a>
                </div>
            </div>
        </div>
        <div class="series-title-content">
            <div class="series-title">{{ getseries.seriesname }}</div>
            <div class="series-title-name">
                Created by <span class="series-title-name1">{{ seriesAdmin }}</span>
            </div>
            <div class="series-title-list">
                <div class="series-title-list-box">
                    <div class="series-titleList-box-name">
                        {{ countData.roundsupply ? countData.roundsupply : "0" }}
                    </div>
                    <div class="series-titleList-box-info">Initial Offering</div>
                </div>
                <div class="series-title-list-box">
                    <div class="series-titleList-box-name">
                        {{ countData.metacount ? countData.metacount : "0" }}
                    </div>
                    <div class="series-titleList-box-info">Uploaded</div>
                </div>
                <div class="series-title-list-box">
                    <div class="series-titleList-box-name">
                        {{ totalSupply ? totalSupply : "0" }}
                    </div>
                    <div class="series-titleList-box-info">Minted</div>
                </div>
            </div>
            <div class="series-center-list">{{ getseries.description }}</div>
            <div class="metaMaks" v-if="!isShow && addroundList.length > 0 && countData.isEnded == 'false'">
                <div class="metaMaks-name" @click="isShowPopBtn(getseries)">
                    <div class="metaMaks-Mint">Mint</div>
                </div>
            </div>
        </div>
        <div class="border-class"></div>
        <!-- list-box -->
        <div class="series-centent" v-show="isShow&& edit == 'true'">
            <div class="series-centent-title">
                <div class="back-router" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div>Back</div>
                </div>
                <div class="series-centent-title-name">Dashboard</div>
                <div class="preview-btn1"></div>
            </div>
            <div class="series-centent-list">
                <div class="series-centent-item">
                    <div class="series-centent-item-title">
                        <div class="series-centent-item-title-name">MAIN FUNCTION</div>
                        <div class="series-centent-item-title-info">
                            Instructions: 1) Add round(s) 2) Upload NFTs 3) End minting 4)
                            Distribute NFTs
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon1.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">1) Add Rounds</div>
                                <div class="series-centent-item-title-info">
                                    Add rounds to increase the upper limit supply of NFTs.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="countData.isEnded == 'true' ? '' : goView('addRounds')">
                            <img v-if="countData.isEnded == 'true'" src="../../assets/images/successImg.png" class="rightImgBtn" />
                            <img v-else src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon2.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">2) Upload NFTs</div>
                                <div class="series-centent-item-title-info">
                                    Click to upload NFTs.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="countData.isEnded == 'true' ? '' : goView('uploadList')">
                            <img v-if="countData.isEnded == 'true'" src="../../assets/images/successImg.png" class="rightImgBtn" />
                            <img v-else src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList" v-if="countData.third == true">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon3.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">3) Close</div>
                                <div class="series-centent-item-title-info">End minting.</div>
                            </div>
                        </div>
                        <!-- isClose?'': -->
                        <div class="fromList-rightIcon" v-if="countData.isEnded == 'false'" @click="closeBtn">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isEnded == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <!-- 4 -->
                    <div class="series-centent-formList" v-if="countData.isEnded == 'true'">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon4.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">
                                    4) Distribution and Settlement
                                </div>
                                <div class="series-centent-item-title-info">
                                    Upload NFTs to decentralized network, distribute NFTs, obtain
                                    settlement funds.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'false'" @click="distribution">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                </div>
                <div class="series-centent-item">
                    <div class="series-centent-item-title">
                        <div class="series-centent-item-title-name">EDIT INFO</div>
                        <div class="series-centent-item-title-info">
                            Editing Information
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon5.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">Series Info</div>
                                <div class="series-centent-item-title-info">
                                    Click to modify the name , logo , description , and blind box
                                    cover.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="goModifyUploadMint" v-if="countData.isRevealed == 'false'">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon6.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">Media Links</div>
                                <div class="series-centent-item-title-info">
                                    Click to enter media links/source.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="goView('mediaLinks')" v-if="countData.isRevealed == 'false'">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- my Collections -->
        <div class="series-centent" v-show="isShow&& getseries.edit == true">
            <div class="series-centent-title">
                <div class="back-router" @click="goBack">
                    <i class="el-icon-back"></i>
                    <div>Back</div>
                </div>
                <div class="series-centent-title-name">Dashboard</div>
                <div class="preview-btn1"></div>
            </div>
            <div class="series-centent-list">
                <div class="series-centent-item">
                    <div class="series-centent-item-title">
                        <div class="series-centent-item-title-name">MAIN FUNCTION</div>
                        <div class="series-centent-item-title-info">
                            Instructions: 1) Add round(s) 2) Upload NFTs 3) End minting 4)
                            Distribute NFTs
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon1.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">1) Add Rounds</div>
                                <div class="series-centent-item-title-info">
                                    Add rounds to increase the upper limit supply of NFTs.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="countData.isEnded == 'true' ? '' : goView('addRounds')">
                            <img v-if="countData.isEnded == 'true'" src="../../assets/images/successImg.png" class="rightImgBtn" />
                            <img v-else src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon2.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">2) Upload NFTs</div>
                                <div class="series-centent-item-title-info">
                                    Click to upload NFTs.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="countData.isEnded == 'true' ? '' : goView('uploadList')">
                            <img v-if="countData.isEnded == 'true'" src="../../assets/images/successImg.png" class="rightImgBtn" />
                            <img v-else src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList" v-if="countData.third == true">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon3.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">3) Close</div>
                                <div class="series-centent-item-title-info">End minting.</div>
                            </div>
                        </div>
                        <!-- isClose?'': -->
                        <div class="fromList-rightIcon" v-if="countData.isEnded == 'false'" @click="closeBtn">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isEnded == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <!-- 4 -->
                    <div class="series-centent-formList" v-if="countData.isEnded == 'true'">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon4.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">
                                    4) Distribution and Settlement
                                </div>
                                <div class="series-centent-item-title-info">
                                    Upload NFTs to decentralized network, distribute NFTs, obtain
                                    settlement funds.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'false'" @click="distribution">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                    </div>
                </div>
                <div class="series-centent-item">
                    <div class="series-centent-item-title">
                        <div class="series-centent-item-title-name">EDIT INFO</div>
                        <div class="series-centent-item-title-info">
                            Editing Information
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon5.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">Series Info</div>
                                <div class="series-centent-item-title-info">
                                    Click to modify the name , logo , description , and blind box
                                    cover.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="goModifyUploadMint" v-if="countData.isRevealed == 'false'">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                    <div class="series-centent-formList">
                        <div class="series-centent-formList-left">
                            <img src="../../assets/images/series-list-icon6.png" class="series-list-icon" />
                            <div class="series-centent-box">
                                <div class="series-centent-item-title-name">Media Links</div>
                                <div class="series-centent-item-title-info">
                                    Click to enter media links/source.
                                </div>
                            </div>
                        </div>
                        <div class="fromList-rightIcon" @click="goView('mediaLinks')" v-if="countData.isRevealed == 'false'">
                            <img src="../../assets/images/rightImgBtn.png" class="rightImgBtn" />
                        </div>
                        <div class="fromList-rightIcon" v-if="countData.isRevealed == 'true'">
                            <img src="../../assets/images/successImg.png" class="rightImgBtn" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- swiper-box -->
        <div :class="seriesList1.length <= 0 ? 'swiper-content1' : 'swiper-content'" v-show="(edit == 'true'||getseries.edit == true?!isShow:isShow) 
      && roundsList.length > 0 
      && countData.isRevealed == 'false'">
            <div class="swiper-title">NFT Preview</div>
            <div class="swiper-wrapper">
                <swiper :options="swiperOption" ref="mySwiper">
                    <swiper-slide v-for="(item, index) of roundsList" :key="index" class="swiper-item">
                        <img :src="`https://ipfs.io/ipfs/${item.image}`" class="swiper-icon" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>
        <div class="mintImg-container" v-show="
        (edit == 'true'||getseries.edit == true?!isShow:isShow) && seriesList1.length > 0">
            <div class="mintImg-content">
                <div class="historyList-title">Items</div>
                <div class="currentIndex0">
                    <div class="historyList">
                        <div :class="seriesList1.length <= 3 ? 'historyLi1' : 'historyLi'" v-for="(item, index) in seriesList1" :key="index">
                            <div @click="goHistory1(item)">
                                <div class="history-img"><img :src="item.image" /></div>
                                <div class="history-information-box">
                                    <div class="history-information1">
                                        <div class="history-name">{{ item.title }}</div>
                                        <div class="history-price">
                                            <div class="history-price-logo">
                                                <img :src="chainImg[chainId]">
                                            </div>
                                            <div>{{ item.price }}</div>
                                        </div>
                                    </div>
                                    <div class="history-information2">
                                        <div class="history-id">#{{ total - index - 1 }}</div>
                                        <div class="history-time">{{ item.timeago }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-actionBar" v-show="item.authentication == false">
                                <div class="history-button">
                                    <span v-if="item.buyState === true&&item.state!==null&&item.state!==''" @click="bugNow1(item)">Buy
                                        now</span>
                                </div>
                                <div class="history-button"></div>
                            </div>
                            <div v-show="item.authentication == true">
                                <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                    <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                    <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                                </div>
                                <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                    <div class="history-button" @click="cancelSaleNew(item)">Cancel Listing</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Mint个数-->
        <div class="mint-number">
            <el-dialog title="Amount" :visible.sync="isShowPop" :before-close="clonseBtn" width="700px" center>
                <div class="input-price">
                    <div class="input-price-title">Select rounds</div>
                    <div :class="activeNames == 1 ? 'collapse-box' : 'collapse-box1'">
                        <el-collapse v-model="activeNames" @change="activeNamesBtn(1)" v-if="isRound == false">
                            <el-collapse-item name="1">
                                <span slot="title" class="title">
                                    <div v-show="isRound == false">Round</div>
                                </span>
                                <div class="heightClass">
                                    <div class="itemPopText" v-for="(item, index) of addroundList" :key="index" @click="goMint(item.saleid, item.ishide, item, index)">
                                        <div :class="
                        item.ishide == 'false'
                          ? 'itemPopText-name'
                          : 'itemPopText-name1'
                      ">
                                            Round {{ index + 1 }}
                                        </div>
                                        <div :class="
                        item.ishide == 'false'
                          ? 'itemPopText-box'
                          : 'itemPopText-box1'
                      ">
                                            <template v-if="chainId==1">
                                                <img v-if="item.ishide == 'false'" src="../../assets/images/ethLogo.png" class="ethLogo" />
                                                <img v-if="item.ishide == 'true'" src="../../assets/images/ethLogo1.png" class="ethLogo" />
                                            </template>
                                            <template v-if="chainId!==1 && chainName[chainId]">
                                                <img :src="chainImg[chainId]" class="ethLogo">
                                            </template>
                                            <div :class="
                          item.ishide == 'false'
                            ? 'itemPopText-box-price'
                            : 'itemPopText-box-price1'
                        ">
                                                {{ item.price }}
                                            </div>
                                        </div>
                                        <div :class="item.ishide == 'false'? 'itemPopText-info': 'itemPopText-info1'">
                                            {{ item.supply?item.supply:'0' }}
                                            <span :class="item.ishide == 'false'? 'also--avali-Name': 'also--avali-Name1'">also available</span>
                                        </div>
                                        <div :class="
                        item.ishide == 'false' ? 'also-Name' : 'also-Name1'
                      ">
                                            jau.{{ item.starttime }} ~ jau.{{ item.endtime }}
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        <div class="collapse-box-class" @click="activeNamesBtn1(2)" v-if="isRound == true">
                            <div v-show="isRound == true" class="itemPopText1">
                                <div class="itemPopText-name2">Round {{ itemIndex + 1 }}</div>
                                <div class="itemPopText-box">
                                    <img :src="chainImg[chainId]">
                                    <div class="itemPopText-box-price">{{ itemData.price }}</div>
                                </div>
                                <div class="itemPopText-info">
                                    {{ itemData.supply?itemData.supply:'0' }}
                                    <span class="also--avali-Name">also available</span>
                                </div>
                                <div class="also-Name">
                                    jau.{{ itemData.starttime }} ~ jau.{{ itemData.endtime }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isPrice">
                    <div class="input-price-title">Quantity</div>
                    <div class="input-box">
                        <input type="text" class="inputClass" :placeholder="`<=${maxmintValue ? maxmintValue : '0'}`" v-model="count" @input="onChange" />
                        <div class="payment-content">
                            <div class="payment-title">You need to pay：</div>
                            <div class="payment-box">
                                <img :src="chainImg[chainId]">
                                <span class="payment-number">{{
                                    priceCount ? priceCount : "0"
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <div class="mintCreate_button">
                        <button :disabled="isBtn && count === ''&&isMint=='next'" :class="{mintCreate_button_true:priceCount != '' && count !== '' && onPrice&&isMint=='next'}" @click="confirm">
                            <span v-if="operationLoading" class="loading_ani">Loading<span class="dotting"></span></span>
                            <span v-else>Confirm</span>
                        </button>
                    </div>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
// const wallet = require("../../web3/common/wallet.js");
const address = require("../../web3/web3Config.js");
import { chainName, chainImg, networkName } from "../../web3/web3Config";
import { queryHistoryNewMarket } from "../../web3/HistorySeriesMinter";
import { createHistoryNewV1ERC721 } from "../../web3/HistorySeriesERC721";
import { queryHistoryMarket } from "../../web3/HistoryMarket";
import { createHistoryV1ERC721 } from "../../web3/HistoryV1ERC721";
import { createHistoryERC72101S } from "../../web3/HistorySeriesERC72101S";
import { createMinter721s } from "../../web3/HistorySeriesMinter01s";
import { getChainIdNetwork } from "../../web3/common/wallet";
import { ethers } from "ethers";
// import store from "@/store";
export default {
    inject: ["reload"],
    data() {
        return {
            chainImg: chainImg,
            baseUrlData: "",
            isMintList: [{
                    url: '0x66BF570046408FC6f0772C96d5B3CeeeA6477460'
                },
                {
                    url: '0xFbF091615931Ffe754f49931fB924ec8A39edFF4'
                },
                {
                    url: '0x922a126121e254cAEF3dEbb5ceE12f1887Ced3e0'
                },
            ],
            isShow: false,
            isShowPop: false,
            dialogVisible: false,
            operationLoading: false,
            isBtn: true,
            isPrice: false,
            roundsList: [],
            priceCount: "",
            count: "",
            closeName: "",
            closeCode: "",
            isShowState: "",
            totalSupply: "",
            total: "",
            valueId: "",
            maxmintValue: "",
            value: "",
            starttime: "",
            endtime: "",
            seriesid: "",
            countData: {},
            getseries: {},
            seriesAdmin: "",
            seriesList1: [],
            addroundList: [],
            activeNames: [],
            swiperOption: {
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
                // spaceBetween: 35,
                slidesPerView: 4,
                // slidesPerGroup : 3,
            },
            historyListQuery2: {
                pagenum: 1,
                pagesize: 4,
            },
            chainName: chainName,
            totalCount: "",
            HistoryERC721: "",
            HistorySeriesERC721: "",
            networkIsShow: "",
            edit: "",
            itemData: {},
            itemIndex: "",
            isRound: false,
            lastTime: 0,
            isMint: '',
            querytokenaddress: '',
        };
    },
    watch: {
        $route: {
            handler: function() {
                this.isShow = this.$route.query.isShow;
            },
            deep: true,
            immediate: true,
        },
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    computed: {
        onPrice() {
            return this.count <= Number(this.maxmintValue);
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        },
        mapState() {
            return this.$store.getters.walletInfo
        }
    },
    methods: {
        activeNamesBtn(id) {
            if (id == 1) {
                this.isRound = false;
            } else {
                this.isRound = true;
            }
            this.isPrice = false;
        },
        activeNamesBtn1() {
            this.isRound = false;
            this.isPrice = false;
            this.count = "";
            this.activeNames = ["1"];
        },
        clonseBtn(done) {
            done();
            this.isPrice = false;
            this.priceCount = "";
            this.isRound = false;
            this.maxmintValue = "";
            this.count = "";
        },
        isShowPopBtn() {
            this.isShowPop = !this.isShowPop;
            this.isMintList.forEach((item) => {
                if (item.url.toLowerCase() == this.mapState.address.toLowerCase()) {
                    this.isMint = 'next'
                }
            })
        },
        moreHistory2() {
            this.historyListQuery2.pagenum += 1;
            this.queryGetseriesnfts();
        },
        goView(view) {
            this.$router.push({
                path: `/${view}`,
                query: {
                    id: this.seriesid,
                    tokenAddress: this.querytokenaddress
                },
            });
        },
        goBack() {
            // this.$router.push({
            //   path: "/myNFTs"
            // });
            this.$router.go(-1)
        },
        goTransaction(item, view) {
            this.itemData = {
                tokenid: item.tokenid,
                price: 1,
                currentPage: 4,
            }
            let transactionState = view
            let historyItem = item
            let tokenaddress = item.tokenaddress
            this.$router.push({ path: '/transaction', query: { itemData: JSON.stringify(this.itemData), item: JSON.stringify(historyItem), transactionState: transactionState, tokenaddress: tokenaddress } })
        },
        cancelSaleNew(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            erc21.sellNft(this.querytokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : this.querytokenaddress, item.tokenid, 0).then(() => {
                let params = {
                    admin: this.mapState.address.toLowerCase(),
                    tokenid: item.tokenid
                };
                this.$axios.post(this.baseUrlData + this.$api.post_cancelseriessale, params).then(() => {
                    this.$message({
                        message: 'Operation succeeded',
                        type: 'success'
                    });
                    _this.reload()
                })
            })
        },
        goHistory(item) {
            this.$router.push({
                path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
            });
        },
        goModifyUploadMint() {
            let id = this.seriesid;
            let tokenAddress = this.querytokenaddress
            this.$router.push({
                path: "/modifyUploadMint",
                query: {
                    id,
                    tokenAddress
                }
            });
        },
        switchShow() {
            this.isShow = !this.isShow;
        },
        goMint(saleid, ishide, item, index) {
            if (item) {
                this.isRound = true;
                this.itemData = item;
                this.itemIndex = index;
            }
            if (ishide === "false") {
                this.isPrice = true;
            }
            if (ishide === "true") {
                this.isRound = false;
                return;
            }
            this.activeNames = "";
            this.valueId = saleid;
            let address = this.mapState.address.toLowerCase();
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                queryHistoryNewMarket().then((erc721) => {
                    erc721.numCanMint(saleid, address).then((resValue) => {
                        this.maxmintValue = resValue.toString();
                    });
                });
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                let ctr01s = createHistoryERC72101S()
                ctr01s.numCanMint(saleid, address).then((resValue) => {
                    this.maxmintValue = resValue.toString();
                });
            }
        },
        onChange() {
            let params = {
                saleId: this.valueId,
            };
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                queryHistoryNewMarket().then((erc721) => {
                    erc721.saleInfo(params.saleId).then((res) => {
                        if (this.count) {
                            this.value = res.price.mul(this.count);
                            this.priceCount = ethers.utils.formatUnits(
                                String(res.price.mul(this.count))
                            );
                        }
                        if (this.count == "") {
                            this.priceCount = "";
                        }
                    });
                });
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                let ctr01s = createHistoryERC72101S()
                ctr01s.saleInfo(params.saleId).then((res) => {
                    if (this.count) {
                        this.value = res.price.mul(this.count);
                        this.priceCount = ethers.utils.formatUnits(
                            String(res.price.mul(this.count))
                        );
                    }
                    if (this.count == "") {
                        this.priceCount = "";
                    }
                });
            }
        },
        queryListData() {
            let network = this.mapState.network ? this.mapState.network : this.networkIsShow
            let params = {
                admin: '',
                seriesid: this.seriesid,
                network: network,
                tokenaddress: this.querytokenaddress
            };
            this.$axios
                .get(this.baseUrlData + this.$api.post_getmetainfos, params)
                .then((res) => {
                    this.roundsList = res.result.slice(0, 8);
                });
        },
        confirm() {
            if (!this.isBtn) {
                return;
            }
            if (this.priceCount !== "" && this.count !== "" && this.count <= this.maxmintValue && this.isMint == 'next') {
                this.operationLoading = true;
                this.mintServer();
            }
        },
        // mint
        mintServer() {
            this.isBtn = false;
            let _this = this;
            let params = {
                saleId: this.valueId,
                _count: this.count,
                address: this.mapState.address.toLowerCase(),
            };
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                queryHistoryNewMarket().then((erc721) => {
                    erc721.mintSeriesToken(params.saleId, params._count, params.address, this.value).then((res) => {
                            if (res) {
                                _this.$message({
                                    message: "Purchase successs",
                                    type: "success",
                                });
                                let edit = true
                                let isShow = false
                                let tokenAddress = this.querytokenaddress
                                this.$router.push({ path: '/series', query: { id: this.seriesid, isShow, edit, tokenAddress } })
                                this.isShowPop = true
                                this.isPrice = false;
                                this.priceCount = "";
                                this.isRound = false;
                                this.maxmintValue = "";
                                this.activeNames = ["1"];
                                this.operationLoading = false;
                                this.count = "";
                                this.isBtn = true;
                                this.queryAddroundList()
                            }
                        })
                        .catch(() => {
                            _this.clearForm();
                        });
                });
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                let ctr01s = createHistoryERC72101S()
                ctr01s.mintSeriesToken(params.saleId, params._count, params.address, this.value).then((res) => {
                        if (res) {
                            _this.$message({
                                message: "Purchase successs",
                                type: "success",
                            });
                            let edit = true
                            let isShow = false
                            let tokenAddress = this.querytokenaddress
                            this.$router.push({ path: '/series', query: { id: this.seriesid, isShow, edit, tokenAddress } })
                            this.isShowPop = true
                            this.isPrice = false;
                            this.priceCount = "";
                            this.isRound = false;
                            this.maxmintValue = "";
                            this.activeNames = ["1"];
                            this.operationLoading = false;
                            this.count = "";
                            this.isBtn = true;
                            this.queryAddroundList()
                        }
                    })
                    .catch(() => {
                        _this.clearForm();
                    });
            }
        },
        clearForm() {
            this.count = "";
            this.maxmintValue = "";
            this.priceCount = "";
            this.isBtn = true;
            this.operationLoading = false;
        },
        // 3
        closeBtn() {
            let now = new Date().valueOf();
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                if (this.lastTime == 0) {
                    this.lastTime = now;
                    let paramsValue = {
                        seriesid: this.seriesid,
                        seriesbaseurl: this.seriesbaseurl ? this.seriesbaseurl : "",
                        tokenaddress: this.querytokenaddress
                    };
                    queryHistoryNewMarket().then((erc721) => {
                        this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsValue).then((res) => {
                            let closeCode = ethers.utils.arrayify("0x" + res.result.signature);
                            erc721.closeMint(closeCode, paramsValue.seriesid).then((value) => {
                                if (value) {
                                    this.reload();
                                }
                            });
                        });
                    });
                } else {
                    if (now - this.lastTime > 6000) {
                        this.lastTime = now;
                        let paramsValue = {
                            seriesid: this.seriesid,
                            seriesbaseurl: this.seriesbaseurl ? this.seriesbaseurl : "",
                            tokenaddress: this.querytokenaddress
                        };
                        queryHistoryNewMarket().then((erc721) => {
                            this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsValue).then((res) => {
                                let closeCode = ethers.utils.arrayify("0x" + res.result.signature);
                                erc721.closeMint(closeCode, paramsValue.seriesid).then((value) => {
                                    if (value) {
                                        this.reload();
                                    }
                                });
                            });
                        });
                    }
                }
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                if (this.lastTime == 0) {
                    this.lastTime = now;
                    let paramsValue = {
                        seriesid: this.seriesid,
                        seriesbaseurl: this.seriesbaseurl ? this.seriesbaseurl : "",
                        tokenaddress: this.querytokenaddress
                    };
                    let ctr01s = createHistoryERC72101S()
                    this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsValue).then((res) => {
                        let closeCode = ethers.utils.arrayify("0x" + res.result.signature);
                        ctr01s.closeMint(closeCode, paramsValue.seriesid).then((value) => {
                            if (value) {
                                this.reload();
                            }
                        });
                    });
                } else {
                    if (now - this.lastTime > 6000) {
                        this.lastTime = now;
                        let paramsValue = {
                            seriesid: this.seriesid,
                            seriesbaseurl: this.seriesbaseurl ? this.seriesbaseurl : "",
                            tokenaddress: this.querytokenaddress
                        };
                        let ctr01s = createHistoryERC72101S()
                        this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsValue).then((res) => {
                            let closeCode = ethers.utils.arrayify("0x" + res.result.signature);
                            ctr01s.closeMint(closeCode, paramsValue.seriesid).then((value) => {
                                if (value) {
                                    this.reload();
                                }
                            });
                        });
                    }
                }
            }
        },
        //4
        distribution() {
            let now = new Date().valueOf();
            if (this.querytokenaddress == this.HistorySeriesERC721) {
                if (this.lastTime == 0) {
                    this.lastTime = now;
                    let admin = this.mapState.address.toLowerCase();
                    let network = this.mapState.network ? this.mapState.network : this.networkIsShow
                    let params = {
                        seriesid: this.seriesid,
                        admin: admin,
                        network: network,
                        tokenaddress: this.querytokenaddress
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_getseriesbaseurl, params).then((res) => {
                        let seriesbaseurlValue = `https://ipfs.io/ipfs/${res.result.seriesbaseurl}/`;
                        queryHistoryNewMarket().then((erc721) => {
                            let paramsData = {
                                seriesid: this.seriesid,
                                seriesbaseurl: seriesbaseurlValue ? seriesbaseurlValue : "",
                                tokenaddress: this.querytokenaddress
                            };
                            this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsData).then((resData) => {
                                let closeCodeData = "0x" + resData.result.signature;
                                erc721.revealAndWithdraw(closeCodeData, paramsData.seriesid, seriesbaseurlValue).then((value) => {
                                    if (value) {
                                        this.reload();
                                    }
                                });
                            });
                        });
                    });
                } else {
                    if (now - this.lastTime > 6000) {
                        this.lastTime = now;
                        let admin = this.mapState.address.toLowerCase();
                        let network = this.mapState.network ? this.mapState.network : this.networkIsShow
                        let params = {
                            seriesid: this.seriesid,
                            admin: admin,
                            network: network,
                            tokenaddress: this.querytokenaddress
                        };
                        this.$axios.get(this.baseUrlData + this.$api.post_getseriesbaseurl, params).then((res) => {
                            let seriesbaseurlValue = `https://ipfs.io/ipfs/${res.result.seriesbaseurl}/`;
                            queryHistoryNewMarket().then((erc721) => {
                                let paramsData = {
                                    seriesid: this.seriesid,
                                    seriesbaseurl: seriesbaseurlValue ? seriesbaseurlValue : "",
                                    tokenaddress: this.querytokenaddress
                                };
                                this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsData).then((resData) => {
                                    let closeCodeData = "0x" + resData.result.signature;
                                    erc721.revealAndWithdraw(closeCodeData, paramsData.seriesid, seriesbaseurlValue).then((value) => {
                                        if (value) {
                                            this.reload();
                                        }
                                    });
                                });
                            });
                        });
                    }
                }
            } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                if (this.lastTime == 0) {
                    this.lastTime = now;
                    let admin = this.mapState.address.toLowerCase();
                    let network = this.mapState.network ? this.mapState.network : this.networkIsShow
                    let params = {
                        seriesid: this.seriesid,
                        admin: admin,
                        network: network,
                        tokenaddress: this.querytokenaddress
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_getseriesbaseurl, params).then((res) => {
                        let seriesbaseurlValue = `https://ipfs.io/ipfs/${res.result.seriesbaseurl}/`;
                        let ctr01s = createHistoryERC72101S()
                        let paramsData = {
                            seriesid: this.seriesid,
                            seriesbaseurl: seriesbaseurlValue ? seriesbaseurlValue : "",
                            tokenaddress: this.querytokenaddress
                        };
                        this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsData).then((resData) => {
                            let closeCodeData = "0x" + resData.result.signature;
                            ctr01s.revealAndWithdraw(closeCodeData, paramsData.seriesid, seriesbaseurlValue).then((value) => {
                                if (value) {
                                    this.reload();
                                }
                            });
                        });
                    });
                } else {
                    if (now - this.lastTime > 6000) {
                        this.lastTime = now;
                        let admin = this.mapState.address.toLowerCase();
                        let network = this.mapState.network ? this.mapState.network : this.networkIsShow
                        let params = {
                            seriesid: this.seriesid,
                            admin: admin,
                            network: network,
                            tokenaddress: this.querytokenaddress
                        };
                        this.$axios.get(this.baseUrlData + this.$api.post_getseriesbaseurl, params).then((res) => {
                            let seriesbaseurlValue = `https://ipfs.io/ipfs/${res.result.seriesbaseurl}/`;
                            let ctr01s = createHistoryERC72101S()
                            let paramsData = {
                                seriesid: this.seriesid,
                                seriesbaseurl: seriesbaseurlValue ? seriesbaseurlValue : "",
                                tokenaddress: this.querytokenaddress
                            };
                            this.$axios.get(this.baseUrlData + this.$api.post_getsignature, paramsData).then((resData) => {
                                let closeCodeData = "0x" + resData.result.signature;
                                ctr01s.revealAndWithdraw(closeCodeData, paramsData.seriesid, seriesbaseurlValue).then((value) => {
                                    if (value) {
                                        this.reload();
                                    }
                                });
                            });
                        });
                    }
                }
            }
        },
        queryObtain() {
            if (this.mapState.chainId) {
                if (this.querytokenaddress == this.HistorySeriesERC721) {
                    let ctr = createHistoryNewV1ERC721();
                    ctr.seriesInfo(Number(this.$route.query.id)).then((res) => {
                        this.totalSupply = res.totalSupply;
                        if (this.totalSupply >= 10000) {
                            this.totalSupply = Math.round(this.totalSupply / 100) / 10 + "K";
                        } else if (this.totalSupply >= 1000) {
                            this.totalSupply = Math.round(this.totalSupply / 100) / 10 + "K";
                        }
                    });
                } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                    let ctr721s = createMinter721s(this.querytokenaddress)
                    ctr721s.seriesInfo().then((res) => {
                        this.totalSupply = res.totalSupply;
                        if (this.totalSupply >= 10000) {
                            this.totalSupply = Math.round(this.totalSupply / 100) / 10 + "K";
                        } else if (this.totalSupply >= 1000) {
                            this.totalSupply = Math.round(this.totalSupply / 100) / 10 + "K";
                        }
                    });
                }
            }
        },
        queryCount() {
            if (this.mapState.chainId) {

                let network = this.mapState.network ? this.mapState.network : this.networkIsShow
                let params = {
                    admin: '',
                    seriesid: this.seriesid,
                    network: network,
                    tokenaddress: this.querytokenaddress,
                };
                this.$axios.get(this.baseUrlData + this.$api.post_getmetacount, params).then((res) => {
                    this.countData = res.result;
                });
            }
        },
        queryGetseries() {
            let params = {
                admin: this.$route.query.admin ? this.$route.query.admin : "",
                seriesid: this.seriesid,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                tokenaddress: this.querytokenaddress
            };
            this.$axios.get(this.baseUrlData + this.$api.post_getseries, params).then((res) => {
                this.getseries = res.result;
                let address = res.result.admin;
                let startText = address.substring(0, 5);
                let endText = address.substring(address.length - 4, address.length);
                this.seriesAdmin = startText + "..." + endText;
            });
        },
        bugNow1(item) {
            if (item.buyState === true) {
                let ctr = queryHistoryMarket();
                ctr.tokenSaleInfo(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid).then((buyState) => {
                    if (buyState.onSale === false) {
                        this.$message({
                            message: "Not available for purchase",
                            type: "warning",
                        });
                    } else {
                        ctr.buyNft(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid, this.mapState.address, buyState.price).then((res) => {
                            let params = {
                                admin: this.mapState.address.toLowerCase(),
                                tokenowner: item.admin.toLowerCase(),
                                tokenid: item.tokenid,
                                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                                tokenaddress: item.tokenaddress
                            };
                            this.$axios.post(this.baseUrlData + this.$api.post_buyseriestoken, params).then(() => {
                                this.$message({
                                    message: "Operation succeeded",
                                    type: "success",
                                });
                                this.reload();
                                console.log("success: bugNow");
                            });
                        });
                    }
                });
            }
        },
        // items-list
        queryGetseriesnfts() {
            let _this = this;
            let params = {
                pagenum: this.historyListQuery2.pagenum,
                pagesize: this.historyListQuery2.pagesize,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                seriesid: this.seriesid,
                tokenaddress: this.querytokenaddress
            };
            this.$axios
                .get(this.baseUrlData + this.$api.post_getseriesnfts, params).then((res) => {
                    this.seriesList1 = this.seriesList1.concat(res.result.list);
                    this.totalCount = res.result.total;
                    this.seriesList1.forEach((item, index) => {
                        if (item.admin == this.mapState.address.toLowerCase()) {
                            _this.seriesList1[index].authentication = true;
                        } else {
                            _this.seriesList1[index].authentication = false;
                        }
                        let address = item.admin;
                        let startText = address.substring(0, 5);
                        let endText = address.substring(address.length - 4, address.length);
                        _this.seriesAdmin = startText + "..." + endText;
                        _this.seriesList1[index].buyState = false;
                        _this.seriesList1[index].followState = false;
                        _this.isBuyFollow1(item, index);
                    });
                });
        },
        isBuyFollow1(item, index) {
            let _this = this;
            let ctr = queryHistoryMarket();
            let erc721 = createHistoryV1ERC721();
            //is buy
            ctr.tokenSaleInfo(this.HistorySeriesERC721 == item.tokenaddress ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid).then(buyState => {
                if (buyState.onSale === false) {
                    _this.seriesList1[index].buyState = false;
                } else {
                    _this.seriesList1[index].buyState = true;
                }
                this.$forceUpdate();
                //is follow
                erc721.tokenEventId(item.tokenid).then(eventId => {
                    //follow time
                    erc721.getConfig().then(mintStartTime => {
                        erc721.eventData(eventId).then(firstMintTime => {
                            let startTime = Number(mintStartTime._mintStartTime) + Number(firstMintTime
                                .firstMintTime)
                            let endTime = Number(mintStartTime._mintEndTime) + Number(firstMintTime.firstMintTime)
                            if (Date.parse(new Date()).toString().substr(0, 10) >= startTime && Date.parse(
                                    new Date()).toString().substr(0, 10) <= endTime) {
                                _this.seriesList1[index].followState = true;
                            } else {
                                _this.seriesList1[index].followState = false;
                            }
                            this.$forceUpdate();
                        })
                    })
                })
            })
        },
        queryAddroundList() {
            let params = {
                admin: "",
                seriesid: this.seriesid,
                network: this.mapState.network ? this.mapState.network : this.networkIsShow,
                tokenaddress: this.querytokenaddress
            };
            this.$axios.get(this.baseUrlData + this.$api.post_addroundList, params).then((res) => {
                this.addroundList = res.result;
                this.addroundList.forEach((item, index) => {
                    this.addroundList[index].starttime = item.starttime.slice(8);
                    this.addroundList[index].endtime = item.endtime.slice(8);
                    if (this.querytokenaddress == this.HistorySeriesERC721) {
                        queryHistoryNewMarket().then((erc721) => {
                            if (item.saleid !== null) {
                                erc721.numSold(item.saleid).then((res) => {
                                    this.addroundList[index].supply -= res.toString();
                                });
                            }
                        });
                    } else if (this.querytokenaddress !== this.HistorySeriesERC721) {
                        let ctr01s = createHistoryERC72101S()
                        if (item.saleid !== null) {
                            ctr01s.numSold(item.saleid).then((res) => {
                                this.addroundList[index].supply -= res.toString();
                            });
                        }
                    }
                });
            });
        },
        goHistory1(item) {
            if (item.admin == this.mapState.address.toLowerCase()) {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                });
            } else {
                this.$router.push({
                    path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=true`,
                });
            }
        },
        async init() {



            this.baseUrlData = localStorage.getItem("baseUrl");
            this.edit = this.$route.query.edit;
            this.querytokenaddress = this.$route.query.tokenAddress
            this.seriesid = JSON.parse(this.$route.query.id);


            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }


            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            this.networkIsShow = networkName[res]




            if (this.seriesid) {
                this.queryGetseries();
                this.queryGetseriesnfts();
                this.queryAddroundList();
                this.queryCount();
            }
            this.queryListData();
            if (this.querytokenaddress) {
                this.queryObtain();
            }

        },
    },
    mounted() {

    },
};
</script>
<style lang="scss" scoped src='../../assets/css/series.scss'></style>
<style lang="scss">
.mint-number .el-dialog__header {
    display: flex !important;
    align-items: center !important;
    border-bottom: 1px solid #e5e8eb;
}

.mint-number .el-dialog__title {
    margin: 0 auto;
    font-weight: bold;
    padding-bottom: 10px;
    box-sizing: border-box;
    font-size: 22px;
    color: #04111d;
}

.mint-number .el-dialog__body {
    padding: 0;
}

.mint-number .el-dialog__footer {
    padding: 0;
}
</style>